* {
    margin: 0px;
    padding: 0px;
}

.homeHead {
    position: relative;
}

.homeHead .welcomeMessage {
    color: orangered;
    font-weight: bold;
    padding: 0.1rem;
    font-size: 1.5rem;
    font-family: monospace;
    margin: 0px;
}

.home {
    margin: 0.5rem
}






.monthstaticChartHead {
    width: 20rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.monthstaticChartHead img {
    width: 100%;
}

.kycHead {
    border: 2px solid black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0.5rem 1rem;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
    margin: 1rem 0rem;

}

.kycMessage {
    margin: 0px;
    font-weight: bold;
    font-size: 1.5rem !important;
    margin: 0rem 1rem;
    color: red !important;


}

.pendingIcon {
    /* color: #ff5722; */
    color: rgb(31, 30, 30);
    font-size: 1.5rem;
}

.kycCompleteButton {
    border-radius: 5px;
    padding: 0.2rem;
    font-weight: bold;

}

.kycCompleteButton :hover {
    background-color: green !important;
    color: white !important;
    ;
}




.currentMonthTitle {
    margin: 0px !important;
    text-align: left;
}

.monthstaticChartHead {
    margin: 0px 0.1rem;
}

.months {
    padding: 0rem 1rem;
    /* border:1px solid red; */
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.month {
    margin: 0rem 1rem;
    padding: 0rem 1rem;
    border-radius: 5px;
    font-weight: bold;
    background-color: rgb(219, 216, 216);
}

.month :hover {
    background-color: #ff5722;
    color: white;
}


.sec1Home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border:1px solid red; */
    padding: 0.2rem;
}

.sec1Home select {
    border: 1px
}

.totalOverview {
    /* border:1px solid red; */
    display: flex;
    flex: column;
    margin: 0.3rem 0rem;
}

.totalOverview .items {
    display: flex;
    flex-direction: column;
    /* border: 2px solid rgb(129, 126, 126); */
    border: 1px solid rgb(219, 216, 216);
    /* border:1px solid green; */
    padding: 0.2rem;
    margin: 0rem 0.2rem;
    border-radius: 5px;
}

.totalOverview .items .col {
    text-align: left;
}

.totalOverview .items .col2 {
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
}

.totalOverview p {
    margin: 0px
}



.selectOrderEarning {
    border: 2px solid black !important;
    border-radius: 5px;
    /* outline:none; */
}


.DashHomeSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.leftSec1Home {
    display: flex;
    flex-direction: row;
}

.selectYear,
.selectMonth {
    border: 2px solid black !important;
    margin: 0.2rem;

}