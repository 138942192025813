*{
    margin:0px;
    padding: 0px;
}
.menuCreationHead{
    background-color: #8fa8a1;
    /* background-color: #d1d1d1; */
    height: 92.6vh;
    padding: 0rem 0.5rem;
    
}
.MenuCreation{
    /* border:1px solid red; */
}
.menuHeading{
color:#ff5722;
}

.tabStr{
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: auto auto auto;
        /* border:1px solid red; */

}
.tabStr .item{
    /* border:1px solid green; */
    margin:0.5rem 0.5rem;


}
.tabStr input, textarea{
    margin: 1px;
    padding: 5px; 
    width: 100% ;
    /* border: 1px solid red; */
    border-radius: 10px;
        background-color: #d1d1d1;

    
}

.tabStr label{
    padding: 0px;
    margin:0px;
    /* border: 1px solid pink; */
}
.tabStrInside{
}

.MenuCreation .tableHead td{
    width: 208px;
    /* border:1px solid red; */
    padding: 5px;
    
}

.MenuCreation .tableBase td{
    width: 200px;
    /* border:1px solid red; */
    padding: 5px;
}
.MenuCreation .tableBase td input{
}

.tdOfImageHeading{
    padding: 30px 0px;
}


.suggAddbtn:hover{
    background-color: black;
    color: white;
}


.addMenuBtn{
  background-color: #0275d8;
  color:white;
  padding: 10px;
  margin:10px;
  border:none;
    border-radius: 10px;
    font-weight: bold;
}
.addMenuBtn:hover{
    background-color: #ff5722;
}





.suggAddbtn{
    font-size: 2rem;
    font-weight: bold;
    border: 2px solid black;
    background-color: #0275d8;
  color:white;
  padding: 5px;
  border:none;
    border-radius: 10px;
    font-weight: bold;
    margin:1rem 0rem;
}
.suggSec4{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}

.optionSelect{
    border: none;
     margin: 1px;
    padding: 8px;
    width: 100% ;
    border-radius: 10px;
    background-color: #d1d1d1;
}
.suggSec3{
    /* border: 1px solid red; */
    /* display: flex; */
    /* flex-direction: column; */
    
}
.suggSec3Inside{
    display: grid;
    grid-template-columns: auto auto;
    border: 1px solid red;

}
.crossBtnHead{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid red;
    margin:0px ;
    padding:0px;
}

.crossBtnHead button{
    padding: 10px;
    border-radius: 20px;
    margin:0.3rem;
}
.suggSec3 .suggList{
    /* border:1px solid black; */
    display: grid;
    grid-template-columns: auto auto auto;
    /* margin:1rem 0rem; */
    padding:0.5rem;
}
.suggSec3 .suggList input{
    border: 1px solid black;
    /* margin:0.2rem 0rem; */
    width: 90%;
    border-radius: 5px;
    padding: 2px;
    background-color: #d1d1d1;

}
.suggListImg{
}






@media screen and (max-width: 480px) {
  .tabStr{
    grid-template-columns: auto auto;
}
.suggSec3 .suggList{
grid-template-columns: auto auto;
}

}


.navMenuCrea{
    padding-top: 0.5rem;
}



.titleMenuHeading{
    font-size: 2rem;
    font-weight: bold;
    /* color: #ff5722 !important; */
    
}
