* {
    margin: 0px;
    padding: 0px;
}

.insideSupport {
    padding: 0rem 0.5rem;
}

.titleSupport {
    font-size: 2rem;
    font-weight: bold;
}

/* SupportPage.css */

.support-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.support-form {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.supportBtn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}