*{
  margin:0px; 
  padding: 0px;
    font-family: "Arial", sans-serif;
}
.cafeRegisterHead{
    font-family: "Arial", sans-serif;
}
.cafeRegisterHead h1{
  text-align: center;
}
.cafeRegister{
  align-items: center;
  justify-content: center;
  padding:0.5rem 0rem;
  position: relative;
}
.itemsHead{
  padding:1rem 2rem;
  width: 100%;
  background-color: #e5dfdf;
    border-radius: 10px;

}
@media only screen and (max-width: 800px) {
  .itemsHead {
    width: 95vw;
  }
}
.itemsHead .items{
  display: flex;
  flex-direction: column;
  margin:1rem 0rem;
}
.itemsHead .items label{
  padding:0px;
  margin:0px;
}
.itemsHead .items input{
    padding: 0.3rem;
      border-radius: 10px;
      outline: none;
      border: none;
      font-size: 1rem;
      width: 100%;
}
.btnCafeSubmit button{
  width:100%;
  color:white;
  background-color: #ff5722;
  border:none;
  border-radius: 10px;
  font-weight: bold;
  padding: 0.5rem;
}

.spinnerHead{
  position: absolute;
  background-color: transparent;
  z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}