*{
    margin:0px;
    padding: 0px;
}
.shippingHead {
    background-color: whitesmoke;
}
.shippingHeadInside{
padding: 0.5rem 0rem;
}
