*{
    margin:0px;
    padding: 0px;
}
.navRegister{
  padding-top: 0.5rem;
}

.menuPageHeading{
  margin-top: 0.5rem;
}

.registerPageHead::before,
.registerPageHead::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7; 
  z-index: -1;
}

.registerPageHead::before {
  background: linear-gradient(315deg, #0000ff, #ffffff);
}

.registerPageHead::after {
  background: linear-gradient(45deg, #67e4de, #ffffff);
}

 @media only screen and (min-width: 1600px) {
  
  #insidePage{
     width: 1600px;  
} 
}
 @media only screen and (max-width: 1600px) {
  #insidePage{
     width: 70%;  
}
}

@media only screen and (max-width: 1200px) {
  #insidePage{
     width: 80%;  
}
} 
@media only screen and (max-width: 992px) {
  #insidePage{
     width: 90%;  
}
} 
@media only screen and (max-width: 600px) {
  #insidePage{
     width: 95%;  
}
} 
.mainPage{
    display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  /* border: 1px solid red; */
}
.onwerForm{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.onwerForm  input,label{
    margin: 10px;
} 
.restaurant-owner-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.registerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  /* border: 1px solid red; */
}

.registerHeading h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.registerPageHead h2 {
  font-size: 18px;
  margin-top: 20px;
}

label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  
}

.RegisterForm input[type="text"],input[type="email"],input[type="checkbox"],input[type="password"] {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 1.2rem;
  border: 2px solid black;
  width:20rem;
  font-style: normal;
  
}

.RegisterButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
  width: 80%;
  font-weight: bold;
  letter-spacing: 1px;
}

.RegisterButton:hover {
  background-color: #0056b3;
}
.alreadyRegister{
    border:none;
    color:#007bff;
    margin:5px 0px;
}

/* Adjust styles for nested elements */
.restaurant-info p {
  margin: 5px 0;
}

.restaurant-info p:last-child {
  margin-bottom: 20px;
}
.InputPassword{
  border: 2px solid black;
}
.RegisterButton{
  margin-top: 1rem;
}

.registerHead {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    /* border: 1px solid red; */
  text-align: center;

}

/* .checkBox{
  padding: 1rem;
  border:1px solid red;
} */


.emailCheck{
  text-align: left;
  color: red;
}

