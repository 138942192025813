*{
    margin:0px;
    padding:0px;
}


.RefundEndUserHead{
    /* border:1px solid red; */
}

.RefundEndUserHead ul{
        list-style-type: disc;
}