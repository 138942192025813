.menu-container {
    /* display: flex; */
    /* flex-wrap: wrap; */
}
.editMenuHead{
    height:92.5vh;
    padding: 0rem 0.5rem;
}
.menu-category {
    background-color: #f5f5f5;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0.4rem;
    /* width: 300px; */
}

.menuController ul {
    list-style: none;
    padding: 0;
}

.menuController li {
    margin-bottom: 10px;
}

label {
    font-weight: bold;
    margin-right: 5px;
}

.menuController input[type="text"] {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
}

.menuController button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: bold;
}

.menuController button:hover {
    background-color: #0056b3;
}

/* Add this CSS to your project's stylesheet */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 27px;
}

.switch input {
    display: none;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

.switch input:checked+.slider {
    background-color: #2196f3;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.switch .slider.round {
    border-radius: 34px;
}

/* Styles for the "Toggle Status" button */
.switch button {
    background-color: #ccc;
    color: #000;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.4s;
}

.switch button:hover {
    background-color: #2196f3;
}

.status {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.catItemList {
    border: 1px solid black;
    border-radius: 5px;
    padding: 0.8rem;
    margin: 0px 0.3rem;
    width: 250px;
}

.menu-category ul {
    display: flex;
    flex-wrap: wrap;
}

.edit-table input {
    padding: 5px;
    margin: 10px 0px;
    border-radius: 10px;
    border: 1px solid black;
}

.edit-table textarea {
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
}

.titleEditMenu{
    font-size:2rem;
    font-weight:bold;
}

.btnOrderNow{
    margin:0.5rem;
    padding:0.4rem;
    border:none;
    border-radius: 5px;
    color:#fff;
    font-weight: bold;
}


.itemNameEditMenu{
    word-wrap: break-word;
        overflow-wrap: break-word;
}

/* .itemNameEditMenuHead{
    display: flex;
    flex-direction: row;
} */

.editMenuHead .growSpinnerHead {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    /* border:1px solid blue; */
}

.editMenuHead .growSpinnerHead  .growSpinner {
    color: "#ff5722";
}