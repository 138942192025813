*{
    margin:0px;
    padding: 0px;
}
.termsHead{
    color:#6b6b6b;
}

.termsTitle{
    text-align: center;
        margin-top: 1rem;
}

.TermsItems{
    margin:2rem 0rem; 
}