 *{
    margin:0px;
    padding: 0px;
}

.navMainPage{
  margin:0px;
  padding: 0px;
}
.navbarHeading{
  
}
.sec2{
    background-image: url("./img/back_orange.jpg");
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    /* height: 80vh; */
    /* position :relative; */
    background-color: rgba(255, 255, 255, 0.1);
    z-index: -1;
    background-position: center; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding:2rem 0rem;
}

.cItem{
   /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  text-align: center;
  /* color : hsl(15, 88%, 50%);; */
  color: white;
  font-weight: bold ;
  z-index: 1;
       text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.cItem h1{
  /* font-size: 4rem; */
  font-weight: bold;
  padding:0px;
  margin:0px
  
}
.cItem h2{
  padding: 0px;
  margin:0px;
}
.sec3{
  background-image: url("./img/back_orange.jpg");
  /* border: 1px solid black; */
  border-radius: 5px;
  padding: 2rem 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  color: white;
  font-weight: bold;
}

.sec3 h4{
  text-align: center;
  padding: 0.5rem 0rem;

} 

.sec3 h6{
  font-weight: bold;
}






.MainPageBody{
  display: flex;
  flex-direction: column;
}

.sec1 img{
  object-fit: cover;
  width: 100%;
}
.sec2 img {
  object-fit: cover;
  width: 100%;
}




.navMainPage{
}
.sec1{
  /* border:1px solid red; */
  position: relative;
  width: 100%;
  height: 80%;
}
/* .content{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    margin:0px;
}


.content img{
  object-fit: contain;
  width: 100%;
}

.content .ezzheading{
  font-size: 3rem;
  margin-left: -7rem;
  padding: 0px !important;
}

.content .item1,.item2{
  font-size: 2rem;
  padding: 0px;
  margin:0px;
    padding: 0px !important;
}

.content .item2{
  margin-left: 5rem;
} */













.sec_benifits_head{
  background-color: whitesmoke;
  background-image: url("./img/Benfits.png");}
.sec_benifits{
  padding: 3rem 0rem;;

}
.benefit_section_card{
  border:2px solid white;
  color :white;
}

.title_benifits{
  color: white;
}

.sec_benifits .benefit_card{
  display: flex;
    flex-direction: row;
}

 .card1 {
  margin: 0.5rem;
  display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius: 5px;
box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);

}

.card1 p{
  text-align: center;
}

.heading{
  font-weight: bold;
  font-size: 1.5rem;
}













.sec_pricing{
    padding:2rem 0rem;
    background-color: white !important;
}

.sec_pricing .pricing_card{

}


.price_heading{
  font-weight: bold;
  font-size: 3rem;
}
.pricing_cards{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pricing_cards .card1{
  padding:0.5rem;
  /* width: 100%; */
    width: 15rem;
    background-color: whitesmoke;
    border:2px solid black;
}

.pricing_cards .card1:hover {
  background-color: #fe7043;
  color: white;
}











.sec_about_head{
background: linear-gradient(#c2bfbf, #ffffff);
}
.sec_about{
  /* border:1px solid red; */
  padding:2rem 0rem;
 

}

.aboutTitle{
  font-weight: bold;
  text-align: center;
  /* padding: 1rem 0rem; */
}

.aboutBody .imgSection{
  /* border:1px solid red; */
  /* margin:3rem */
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutBody .imgSection img{
width: 100%;
height: 100%;
}

.aboutBody .aboutContent {
  /* border:1px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding:2rem;
  font-weight:900;
  font-size: 1.5rem;
  color: #6b4141;
}
.aboutBody{
  display: grid;
  grid-template-columns: 1fr 3fr;
}

@media screen and (max-width: 991px) {
  .aboutBody {
    color: blue;
    display: grid;
      /* grid-template-rows: 0.5fr 0.1fr; */
      grid-template-columns: 1fr;
  }
    .aboutBody .imgSection {
    
    }

   .aboutBody .imgSection {
    /* border:1px solid blue; */
    display: flex;
      align-items: center;
      justify-content: center;
      padding:1rem;
   }
        .aboutBody .imgSection img{
           /* height: 50%; */
           /* width: 50%; */
           /* height: 20rem; */
           /* width: 15rem; */
        }


                .aboutBody .aboutContent {
                    padding: 1rem;
                    font-size: 1rem;
                }
}















.acc_creation{
  padding:0.5rem 0rem;
  background-image: url("./img/back_black_img.jpg");
}
.acc_creation .sec_benifits{
  border:none;
}

.acc_creation .title{
  color: white;
  padding:1rem 0rem;
}
.acc_creation .benefit_card{
}
.acc_creation .benefit_card  .card1{
    border: none;
    color: white!important;
    padding:0.5rem;
    margin:0rem 1rem;
}
.acc_creation .acc_creation_item1{
 background-color: #3a9acd;
}

.acc_creation .acc_creation_item2 {
  background-color: #dc748e;
}

.acc_creation .acc_creation_item3 {
  background-color: #514fe0;
}







/* 

.menu_feature .benefit_card .card1{
  color:black;
  margin:0rem 2rem;
  border:none;
}

.menu_feature .sec_benifits{
  border:none;
}

.menu_feature .sec_benifits .title{
  color: black;
  font-weight: bold;
  padding:1rem 0rem;
}

.menu_feature .benefit_card .card1 p{
  color:white;
  font-weight: bold;
}

.menu_feature .card1 img{
  height: 10rem;
  padding:1rem;
}

.menu_feature  .menu_feture_item1{
  background-color: #2965bf;

}
.menu_feature .menu_feture_item2 {
  background-color: #fe7043;
}

.menu_feature .menu_feture_item3 {
  background-color: #ff4747;
} */








.menu_feature_head{
  /* border:1px solid red; */
    padding: 2rem 0rem;
}

.sec_menu_feature{
  /* border:1px solid green; */
}

.menu_feature_title{
  font-weight: bold;
  padding:1rem 0rem;
}
.menu_feature_cards{
display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:3rem;
}
.menu_feature_card{
  /* border:2px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  color:white;
  font-weight: bold;
  padding: 1rem;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.menu_feature_card img {
height: 10rem;
width: 100%;
padding:1rem;
}
.menu_feature_card *{
  /* border:1px solid green; */
}
.menu_feature_card_img{
  width: 100vw;
  height: 15rem;

}

.menu_feature_card_img img{
  width: 100%;
  height: 100%;
}

.menu_feature_card1{
  background-color: #2965bf;
}
.menu_feature_card2 {
  background-color: #fe7043;
}
.menu_feature_card3 {
  background-color: #ff4747;
}