.cookie-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

h1 {
    /* text-align: center; */
}

h2 {
    margin-top: 20px;
}

p {
    /* line-height: 1.6; */
}

ul {
    list-style-type: none;
}

ul li {
    margin-bottom: 10px;
}

section {
    margin-bottom: 40px;
}


.contactbutton{
    background-color: white;
    border:none;
    padding:0px 2px;
    color:blue;
    text-decoration: underline;
}