*{
        font-family: "Arial", sans-serif;
}


.column {
    float: left;
    width: 33.3%;
    margin-bottom: 16px;
    padding: 0 8px;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
}

.about-section {
    padding: 50px;
    text-align: center;
    background-color: #474e5d;
    color: white;
}



.container::after,
.row::after {
    content: "";
    clear: both;
    display: table;
}

.title {
    color: grey;
}
.aboutusPage{
    user-select: none;
   
}
.aboutusPage .button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.button:hover {
    background-color: #555;
}

@media screen and (max-width: 650px) {
    .column {
        width: 100%;
        display: block;
    }
}

.contentLess{
    max-height: 3.2em;
    overflow: hidden;
    margin:0px;
}
.contentLess,.contentFull{
    margin: 0px;
}



.seeButton{
    margin:0px;
    outline: none;
    border:none;
    background-color: white;
    color:#0000EE;
text-decoration: underline;}




.aboutUsContainer h5{
    text-align: center;
}
.section1 h1 {
    text-align: center;
}

.MenuCreation .aboutusPage .section1{
background-color: hsl(18, 100%, 95%);
}
.section1 h5{
    text-align: center;
}
.section1Items{
    padding:0.5rem 0rem;
}

.section1Items p{
    color: #6b6b6b;
}

.ourTeamTitle{
    margin-top: 2rem;
}

