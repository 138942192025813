/* .container2 {
    position: relative;
    width: 300px;
    height: 400px;
    border: 1px solid black;
    display: flex;
        align-items: center;
        justify-content: center;
}

.small-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10rem!important;
    height: 10rem!important;
}

.mainImg{
    width: 100%;
    height: 100%;

}


.menuName , .scanYour{
position: absolute;
font-size: 1.5rem;
    font-weight: bold;
   
}
.menuName{
    top:0px;    
}

.scanYour{
    bottom: 0px;
} */


* {
    margin: 0;
    padding: 0;
    /* border:1px solid red; */
}

.kycBody {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 2px solid red; */
}

.section1 {
    /* border:2px solid green; */
    display: flex;
    flex-direction: column;
    background-color: #e5dfdf;

    /* padding: 2rem; */
    font-family: "Arial", sans-serif;
    /* margin: 5rem; */
    border-radius: 5px;
    margin-top: 0px;;
    /* width: 40%; */
}

@media screen and (min-width: 900px) {
    .section1 {
        width: 40%;
    }
}

.item {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0rem;
}

.item label {
    margin-bottom: 0.5rem;

}

.item input,
textarea {
    padding: 0.3rem;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 1rem;
    width: 100%;
}

.item textarea {
    border: none;
    outline: none;
    border-radius: 10px;


}
.kycBody .title{
    /* border:1px solid red; */
    width: 100%;
    margin:0.1rem;
    color:black;
    font-weight: bold;
    padding:1rem 0rem;
}


.skipHead{
    width: 100%;
    /* border:1px solid blue; */
    margin:0px;
    padding: 0px;
}
.skip{
    border:1px solid blue;
    border:none;
    border-radius: 5px;
    float:right;
    padding:0.2rem 0.4rem;
    background-color:#ff5722;
    color:white;
    font-weight: bold;
}
.skip :hover{
background-color:white;
    color: #ff5722;
}



.section1 .item button {
    color: white;
    background-color:#ff5722;
    border: none;
    font-weight: bold;
    padding: 0.5rem 0rem;
    border-radius: 10px;
}
.item button:hover{
    color:#ff5722;
    background-color: white;
    border:2px solid #ff5722;
}
.section1 .message p{
    color:red;
}