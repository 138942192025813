* {
    margin: 0;
    padding: 0;
}
.query-container{
    /* border: 5px solid red; */
    /* height: 99vh; */
}
.headOfPage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.section1 {
    display: flex;
    flex-direction: column;
    background-color: #e5dfdf;
    padding: 1rem;
    font-family: "Arial", sans-serif;
    margin: 0.5rem;
    border-radius: 10px;
}

.item {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0rem;
}

.item label {
    margin-bottom: 0.5rem;

}

.item input,
.headOfPage textarea {
    padding: 0.3rem;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: bold;
}

.item textarea {
    border: none;
    outline: none;
    border-radius: 10px;
    background-color: white;


}



.query-container .item button {
    color: white;
    background-color:orangered;
    border: none;
    font-weight: bold;
    padding: 0.5rem 0rem;
    border-radius: 10px;
    font-weight: bold;

}
.query-container .message{
    color:red;
}



.messageLabel{
    display: flex;
    flex-direction: row;
}

.messageLabel p{
    padding-left: 5px;
    color :red;
    font-weight: bold;
}

.emailContact{
    background-color: whitesmoke;
}
.emailContact .emailAdd{
    color:blue;
    text-decoration: underline;

}