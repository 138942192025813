
.slider-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
  padding: 0px 70px;
    /* border-radius: 50%; */
}
/* .imageHead{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid red;
} */