*{
    margin:0px;
    padding:0px;

}
.getOrdersHead{
    /* height:92.6vh; */
    padding: 0rem 0.5rem;
}

.headH{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .headH{
    grid-template-columns: repeat(1, 1fr);
}
}
.orderLoad{
    margin:1rem;
    padding: 0.3rem;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid white;
    background-color: rgba(102,106,210,255);;
}
.orderLoad:hover{
    background-color: #ff5722;;
}
.headO{
    border: 1px solid black;
    border-radius: 5px;
   }

.tabOrderNo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border:1px solid black;
    border-radius: 5px;
    background-color: rgba(102,106,210,255);
}

.tabNo,.orderNo{
margin:0.5rem;
padding: 0.2rem;
font-weight: bold;
border:1px solid black;
border-radius: 5px;
background-color: rgba(120,125,247,255);
}


.sec1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.sec1 input{
    border:1px solid black;
    padding: 5px;
}
.OrderTitle{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin:0px !important;
    padding:0px !important;
}

.titleGetOrders{
    font-weight: bold;
    font-size: 2rem;   
}



.growSpinnerHead {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    /* border:1px solid blue; */
}

.growSpinnerHead .growSpinner {
    color: "#ff5722";
}