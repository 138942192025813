.footer {
    display: flex;
    flex-direction: column;
    color: wheat;
    background-color: black;
    padding: 2rem;
}

.footsection1 {
    display: wrap;
    /* flex-direction: row; */
    /* justify-content: space-around; */
}


.sectionn1,
.section2,
.section3 {
    /* display: flex; */
    flex-direction: column;
    text-align: left;
    /* justify-content: space-around; */
    /* border:1px solid white; */
    padding:0.5rem 0rem;
}

.footer button {
    border: none;
    background-color: black;
    color: #5858cf;
    text-decoration: underline;
    /* border:1px solid white; */
    text-align: left;
    padding-left: 1rem;
}

.footerHead{
        background-color: black;
}
h4{
    /* border:1px solid white; */
    padding:0px;
}

.footer-info{
    margin-top:1rem;
}