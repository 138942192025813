@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
.input-field input {
  font-family: "Poppins", sans-serif;
}

.container1 {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

.loginForm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.loginForm .sign-up-form {
  opacity: 0;
  z-index: 1;
  /* border:1px solid red; */
}

.loginForm .sign-up-form  *{
  /* border:1px solid red; */
}

.loginForm .sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2.5rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
  box-shadow: 0 2px 5px  rgba(0, 0, 0, 0.6);
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 3px solid #333;
  text-decoration: none;
  font-size: 1.3rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #f7543f;
  border-color: #b83120;
}

.btn {
  width: 150px;
  background-color: #ee6654;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  box-shadow: 0 2px 5px  rgba(0, 0, 0, 0.6);
}

.btn:hover {
  background-color: #f14b35;
}
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container1:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background:#f14b35;
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  
  width: 70%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
  align-items: flex-end;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 3px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  box-shadow: none;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.container1.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container1.sign-up-mode .left-panel .image,
.container1.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container1.sign-up-mode .signin-signup {
  left: 25%;
}

.container1.sign-up-mode .loginForm .sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container1.sign-up-mode .loginForm .sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container1.sign-up-mode .right-panel .image,
.container1.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container1.sign-up-mode .left-panel {
  pointer-events: none;
}

.container1.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .container1 {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container1.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.5rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container1:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container1.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container1.sign-up-mode .left-panel .image,
  .container1.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container1.sign-up-mode .right-panel .image,
  .container1.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container1.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  .loginForm {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .container1 {
    padding: 1.5rem;
  }

  .container1:before {
    bottom: 72%;
    left: 50%;
  }

  .container1.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}


.message{
  color:#ff0505;
}

.passwordHead {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  /* justify-content: flex-end */
  width: 100%;
  border:1px solid green!important;

}
.passHeadHead{
    width: 100%;

}
.passHeadHead .input-field{
  width: 99%;
}

.passCheckbox {
  /* border: 2px solid #333; */
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  float: left !important;
  width: 20px;
  height: 20px;
}


.conditionsAndNoti{
  /* border:1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.conditionsAndNoti button{
  border: none;
  background-color: white;
      color: #0000FF;
      text-decoration: underline;
      margin:0px 5px;


}
.checkbo{
  height: 20px;
  width: 20px!important;
  margin:0px 5px
}

.conditons{
  /* border:1px solid green; */
}

.noti{
  /* border:1px solid black; */
}



.loginSignUpTopHead{
  
}
.loginSignUpHead{
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginSignUpHead .growSpinnerHead{
  /* border:1px solid black; */
    opacity: 1;
  
}


.otpHead{
  width: 100%;
  /* border:1px solid red; */
  /* background-color: yellow; */
  display: flex;
  align-items: column;
  flex-direction: row;
  justify-content: end;

}



.otpHead button{
  float: left;;
  border-radius: 10px;
  font-weight: bold;
  background-color: #f14b35;
  color: white ;
  border:none;
  /* margin:0.5rem 0.1rem; */
  padding:0.3rem;
  margin-right: 2rem;
    margin: 0px !important;;
}

.otpHead input{
  border:2px solid #333;
  margin: 0.5rem;
  padding:0.2rem;
  border-radius: 5px;
  outline: none;
  font-weight: bold;
  
}


.emailInputHead{
  width: 100%;
  border:1px solid green;
      position: relative;
  width: 100%;
    border-radius: 30px;
    margin:0.5rem 0rem;
      background-color: hsl(0, 0%, 94%);
        font-weight: bold;
        border:none;
        outline: none;
          box-shadow: 0 2px 5px  rgba(0, 0, 0, 0.6);  
          display: flex;
          flex-direction: row;
}

.emailInput{
  display: flex;
  flex-direction: column;
    width: 90%;
    font-weight: bold;
}

.emailInput input{
      background-color: hsl(0, 0%, 94%);
border:none;
outline: none;
margin: 0.4rem;
font-weight: bold;
width: 100%;


}
.emailInput input::placeholder{
 color: #aaa;
}
 .emailCheckSymbol{
 width: 10%;
 display: flex;
 align-items: center;
 justify-content: center;
}



.passHeadHead{
  margin:0.5rem 0rem;
}
.passHead1{
  width: 100%;
  display: flex;
   flex-direction: row;
   border-radius: 30px;
  box-shadow: 0 2px 5px  rgba(0, 0, 0, 0.6);
  background-color: hsl(0, 0%, 94%);


}

.passInput1{
  width: 90%;
  /* margin:0.5rem 0rem; */

}
.passInput1 input{
  width: 100%;
  outline: none;
   border-radius: 30px;
    padding:0.5rem;
        font-weight: bold;
        border:none;
        outline: none;
          background-color: hsl(0, 0%, 94%);
}

.passInput1 input::placeholder{
 color: #aaa;
}

.passCheck1{
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;

}


.passCheck1 input{
  width: 100%;
  outline: none;
}



.forgotPassHead{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.forgotPassHead button{
  background-color: white;
  font-weight: bold;
  border:none;
  color:#0000FF;
  text-decoration: underline;
  font-size: 0.8rem;
}
