*{
    margin:0px;
    padding: 0px;
}


.kycTitle{
    /* border:1px solid green; */
        /* margin: 0px !important; */
        text-align: left;
        font-weight: bold;
        margin:0.7rem 0rem!important;
}
.kycAdminHead{
    /* border:1px solid red; */
    margin:0.5rem;
    position: relative;
}

 .kycAdminHead table {
     border-collapse: collapse;
     width: 100%;
 }

 .kycAdminHead th,
  .kycAdminHead td {
     padding: 0.2rem;
     text-align: left;
     width: 25%;
    /* background-color: #f2f2f2; */
 }

  .kycAdminHead th {
     background-color: #f2f2f2;
 }
   .kycAdminHead tr{
    border:2px solid black;
   }


 .verifyButton{
    padding:0.1rem 1rem;;
    background-color: #ff5722;
    color: white;
    font-weight: bold;
    border:none;
    border-radius: 15px;
 }




 .kycModalBody{
    height: 90vh;
    overflow-y: scroll;
    background-color: white;
    width: 30rem;
    border-radius: 10px;
 }

 @media screen and (max-width: 768px) {
   .kycModalBody{
    width: 90vw;
   }
 }

 .modalItems{
    /* width: 500px; */

 }

 .modalItem{
    /* border:1px solid red; */
    margin:0.4rem 0px;
 }
 .modalItem label{
  /* border:1px solid blue; */
  margin:0px;
  width: 100%;
 }


 .modalItem input{
 border:2px solid black;
 outline:none;
 width: 100%;
 font-weight: bold;
 padding: 0.1rem 0.5rem;
 border-radius: 5px;
 background-color: #d5d4d4;
 }


 .AdminKycSpinner{
   position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
 }


 .submitKycBtn{
   display: flex;
      justify-content: center;
      margin:1rem 0rem;
 }
 .submitKycBtn button{
   background-color: #ff5722;
   color:white;
   font-weight: bold;
   border-radius: 5px;
   border:none;
   padding:0.2rem;
 
 }

 .kycVerifiedId{
   background-color: white!important;
 }