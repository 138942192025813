* {
    margin: 0px;
    padding: 0px;
}


.settingHead{
    /* border:1px solid red; */
}
.setting {
    margin:0.4rem;
        /* border: 1px solid green; */
        display: flex;
        flex-direction: column;
}

.titleSetting {
    font-size: 2rem;
    font-weight: bold;
}


.modalEmailModalBody{
    padding:0.5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.modalEmailModalBody .label1 {
    border-radius: 5px;
    margin: 0px;
    padding: 0px;
    width: 100%;
    font-weight: bold;
    margin:0.1rem 0.1rem;
    margin-top: 0.5rem;

}
.modalEmailModalBody .input{
    border: 2px solid black;
    border-radius: 5px;
    outline: none;
    padding:0.1rem;
    font-weight: bold;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    background-color: #d1d1d1;

    
}
.modalEmailModalBody .input:active {
    background-color: red;
}

.bottomButton{
    /* border: 1px solid black; */
        display: flex;
            flex-direction: row;
            justify-content: flex-end;
}
.bottomButton button {
    border-radius: 10px;
    font-weight: bold;
    background-color: #ff5722;
    border:none;
    color :white;
    margin: 0.3rem;
    padding:0.2rem 0.3rem;


}


.inputHead{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.emailEditSymbol{
    padding:0.1rem;
    font-size: 1.2rem;
    border:2px solid black;
    border-radius: 5px;
    margin:0.1rem;
    width: 100%;  
}
/* .emailEditSymbol :hover{
    background-color: #ff5722;
    color:white
} */



.settingBody{
    
}

.settingBody .edit_menu{
    /* border:1px solid red; */
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 0.8rem;
    
}
.edit_menu_title{
                font-weight: bold;
                /* border:2px solid rgb(211, 209, 209); */
                border-radius: 5px;
                padding: 0.3rem;
                
}
.settingBody .edit_menu button{
    text-align: left;
    background-color: white;
    border:none;
        color:#0000FF;
        /* padding-left: 0.5rem; */
        font-weight: bold;
        padding:0.3rem;
    background-color: rgb(219, 227, 224);}
.edit_detail_button{
    border:1px solid rgb(227, 220, 220);
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color: rgb(219, 227, 224);
}


.editMessage{
    color:green;
    margin:0px;
    font-weight: bold;
}

.modalBody{
    position: relative;
}
.modalSpinner{
    position: absolute;
        top: 50%!important;
        left: 50%!important;
        transform: translate(-50%, -50%)!important;
}