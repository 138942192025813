*{
          font-family: "Arial", sans-serif;
}
.navbarTop *{
  margin:0px ;
  padding:0px;

}
.navbarTop {
  background-color: white;
  color: #ffffff;
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1600px) {
  .navbarTop {
    display: block;
  }
}
.navbarTop .navbarTopInside{
border: 1px solid #ccc;
border-radius: 5px;
}
@media screen and (min-width: 1601px) {
  .navbarTop .navbarTopInside {
    width: 1600px;
  }
}
.toggleBtn {
  border: 2px solid black;
  margin: 5px;
}


.navbarHeading {
  margin-left: 1rem;
  cursor: pointer;
  color: #ff5722;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.6rem;
}
.navbarHeading:hover {
  color: #ff5722;
}
.collapseItem {
  border: 1px solid black;
  border-radius: 10px;
  margin: 0rem 0.3rem;
  font-weight: bold;
}
.collapseItem:hover {
}
.sus {
  display: flex;
}
.dropDownHead {
  background-color: none;
}
.dropDownHead .dropDownItems {
  margin-bottom: 0.2rem;
  color: black;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
}

.loginItem {
  display: flex;
  flex-direction: column ;
  border: none;
  }



.HomePageLink .collapseItem{
  color:#ff5722;
  font-weight: bold;
  border:2px solid #ff5722;
  margin:0.1rem;

  padding:0.1rem;
  border-radius: 0px ;
  border-radius: 5px;
    width: 98%;
}
.HomePageLink .collapseItem:hover{
  color:white;
  background-color: #ff5722;
}

.toggleBtn{
  border:none;
  outline: none;
  box-shadow: none;
}
.toggleBtn:hover{
    border: none;
      outline: none;
      box-shadow: none;
      color:black;
}

.items{
  display: flex;
  flex-direction: row
}