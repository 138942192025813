*{
    margin: 0px;
    padding: 0px;
}
.forgotPageHead{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.forgotPage{
box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 20rem;
    padding:0.5rem;
    margin:2rem 0rem;
        position: relative;
        border:1px solid red;
}
@media (max-width: 400px) {
    .forgotPage {
        border:1px solid red;
        width: 100vw;
    }
}
@media (max-width: 300px) {
    .forgotPage {
        border: 1px solid red;
        /* width:80%; */
    }
}

.forgotPageTitle{
    /* border:1px solid red; */
    font-size: 1.5rem;
    font-weight: bold;
}

.forgotPage .forgotItem{
/* border:1px solid blue; */
margin:0.5rem 0rem;
}

.forgotPage label{
    /* border:1px solid blue; */
    margin:0px;
}
.forgotPage input{
    width: 100%;
    outline: none;
    border:2px solid black;
    border-radius:5px;
    padding: 0.2rem;
    font-weight: bold;
}

.forgotOtp{
    /* border:1px solid red; */
    display: flex;
    justify-content: flex-end;
}

.forgotOtp button{
    background-color: #ff5722;
    color: white;
    font-weight: bold;
    padding:0.1rem;
    border:none;
    border-radius: 5px;
}


.changeBtn{
/* border: 1px solid red; */
    display: flex;
    justify-content: center;
}
.changeBtn button{
   
    background-color: #ff5722;
        color: white;
        font-weight: bold;
        padding: 0.3rem;
        margin: 0.5rem 0rem;
        border: none;
        border-radius: 5px;
}

.forgotMessage{
    /* border:1px solid red; */
    color: red;
    margin: 0px !important;;
}

.forgotLoginSignUp{
    font: 0.8rem;
    border:none;
    background-color: white;
    color:#0000FF;
    font-weight: bold;
    text-decoration: underline;
}

.forgotGrowSpinner{
    position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
}