*{
    margin:0px;
    padding:0px;
}
.photoCheckHead{
    border:1px solid red ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.photoCheckHead input{
  border: 1px solid blue;
  padding: 0.5rem;
  margin :1rem;
  font-size: 1rem;
}

.checkImg{
  width: 100px;
  height: 100px;
}

.groupOfImg
{
  display: grid;
grid-template-columns: repeat(3, 1fr);
border:1px solid red;
}