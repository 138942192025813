*{
    margin:0px;
    padding:0px;
}

.qrCodeHeadTop{ 
    height:92.6vh;
    display:flex;
    flex-direction:column;
    padding: 0rem 0.5rem;
 }

.qrCodeHead{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.qrCodeHead h2{
    text-align: center;
}
.qrCode{
    border: 1px solid black;
    background-image:url('./photos/217.png') ;
    object-fit:fill;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}
.qrCodeInside{
display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.qrCodeInside h2{
    margin : 1rem 1rem;
    font-weight: bold;
    color: black;
}

.downloadBtn{
    text-align: center;
    margin-top: 1rem;
}
.downloadBtn button{
    padding: 0.5rem;
    border-radius: 5px;
    border:none;
    background-color: #ff5722;
    color:white;
    font-weight: bold;

}

.titleQRCode{
    font-size: 2rem;
    font-weight: bold;
}




.qrCodeHeadTop {
    position: relative;
}

.qrCodeHeadTop.growSpinnerHead {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



.container2 {
    position: relative;
    width: 270px;
    height: 350px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}


.small-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 200px; */
    /* height: 200px; */
}

.mainImg {
    width: 100%;
    height: 100%;

}


.menuName,
.scanYour {
    position: absolute;
    font-size: 1.5rem !important;
    font-weight: bold;

}

.menuName {
    top: 0px;
}

.scanYour {
    bottom: 0px;
}

/* Styles for screens with a maximum width of 600px */
@media (max-width: 280px) {
    .container2 {
        width: 230px;

    }

    .qrCodeImg {
        width: 150px;
        height: 190px;
    }

    .scanYour {
        font-size: 1rem !important;
    }
}