/* #fe734c theme colorrgba(254,115,76,255) */
/* #e37d5e fontcolor  rgba(227,125,94,255)#e77a63rgba (231,122,99,255) */
/*   color:rgb(138, 140, 146); */


.user{
  background-color: hsl(25, 100%, 95%);
  background-color: whitesmoke;

}


* {
  margin: 0px;
  padding: 0px;
}

/* * {
  font-family: "Roboto", sans-serif;
} */

.userPageHead {
  color: #333333;
  position: relative;
}

.menu {
  position: relative;
}




.shopLogo {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shopLogo img {
  width: 100%;
  width: 4rem;
  padding: 0.5rem;
}



.shopName h1{
  margin: 0px;
  padding: 0.5rem 0rem;
    font-family: cursive;
}


.hr1 {
  border: 1px solid black;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.searchItem {
  padding: 0.2rem 0;
}



.Advertise {
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Advertise img {
  width: 100%;
  height: 200px;
  object-fit: fill;
}

.itemPrice {
  color: rgb(138, 140, 146);
}

.logoAndCate {
  border: 1px solid rgb(138, 140, 146);
  border-radius: 5px;
  padding: 0.2rem;
  /* margin: 0.2rem; */
  margin:0.2rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
    display: flex;
}

.logoAndCate .menuName {
  display: flex;
  flex-direction: row;
}

.logoAndCate .menuName h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.logoAndCate .menuName .menu {
  /* margin-left: 0.2rem; */
  font-size: 1.2rem;
  font-weight: bold;
}

.logoIcon {
  margin: 0px;
  padding: 0px;
  background-color: #ff5722;
  padding: 0.2rem 0.8rem;
  color: white;
  font-size: 2rem;
  border-radius: 50px;
  margin-right: 1rem;
}

.logoAndCate .cateName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
    /* margin-right: 0.5rem; */
}

.cateName select {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoAndCate .category {
  /* border: 1px solid red; */
  border:1px solid black;
  border-radius: 5px;
  margin:0.1rem;

  padding: 0.2rem;
  font-size: 0.8rem;
}



.btnSeeMoreHead{
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnSeeMore {
  background-color: #ff5722;
  color: white;
  border-radius: 10px;
  border: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  border: none;
  text-align: center;
  padding: 0.3rem;
  margin-top: 1rem;
}


.billThank{
  text-align: center;
  margin-top: 1rem!important;
}














.subCateImg {
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
}

.ListAllItem img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.stickyFooter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  position: sticky;
  bottom: 0.5px;
  background-color: whitesmoke;
  border: 1px solid black;
  border-radius: 20px;
  padding: 0.5rem 0.2rem;
  z-index: 1;
}

.stickyFooter img {
  width: 20%;
 
}

.stickyFooter button {
  padding: 0rem 0.3rem;
  border: none;
  display: inline;
}

.stickyFooter h6 {
  margin: 0px;
  padding-top: 0.5rem;
}

.payWord {
}

.ListAllItem img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.subItem {
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  margin: 1rem 0rem;
  border-radius: 10px;
}

.iNameAndPrice {
  width: 70%;
  padding: 1rem;
}

.itemQuantityBtn {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.itemQuantityBtn button {
  padding: 0.5rem;
}

.groupOfImg {
  display: flex;
  flex-direction: column;
}

.moveTop,
.moveBottom {
  font-size: 2rem;
  font-weight: 800;
  color: #ff5722;
}

.scrollBtn {
  position: sticky;
  bottom: 75px;
}

.scrollBtn .scrollBtnInside {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.scrollBtn .scrollBtnInside button {
  margin-right: 5px;
  margin-top: 5px;
  padding: 0px 5px;
  border-radius: 80px;
  border: 2px solid #ff5722;
  background-color: none;
  background-color: transparent;
}



.shopName {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}


@media screen and (max-width: 992px) {
  .shopLogo {
    width: 20%;
  }

  .shopName {
    /* width: 80%; */
  }
}

@media screen and (min-width: 993px) {
  .stickyFooter img {
    width: 10%;
  }
}

.des-view .head-cate {
  position: relative;
}

.bothHeadSub {
  border-radius: 5px;
  margin: 2rem 0rem;
}

.des-view .head-cate img {
  width: 100%;
  height: 20vh;
  border-radius: 10px;
  object-fit: fill;

  border: 1px solid rgb(138, 140, 146);;
}

.des-view .head-cate .head-cate-name {
  position: absolute;
  bottom: 0.5rem;
  left: 1rem;
  color: black;
  font-weight: 500;
  font-size: 3rem;
  text-shadow: -0.5px -0.5px 0 #fff,  0.5px -0.5px 0 #fff,
     -0.5px 0.5px 0 #fff,
  0.5px 0.5px 0 #fff;
 
  letter-spacing: 1px;
}

.des-view .sub-cate {
  padding: 0rem 0.1rem;
  margin: 0.1rem 0rem;
}

.des-view .sub-cate .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.3rem 0rem;
  padding: 0.5rem 0px;
  border: 1px solid #989696;
  border-radius: 10px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
}

.des-view .sub-cate h5 {
  margin: 0px;
  padding: 0rem 0.5rem;
}

.AddItemBtn {
  margin-right: 10px;
  font-weight: bold;
  background-color: #ff5722;
  color: white;
  border-radius: 10px;
  padding: 0.5rem 1.2rem;
  border: none;
    cursor: pointer;
}

.addSubQuan {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0rem 0.5rem;
  border: none;
  background-color: #ff5722;
  border-radius: 10px;
  color: white;
}

.quantity {
  padding: 0rem 0.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  z-index: 100;
      flex-direction: row;
      background-color: #ff5722;
      /* border-radius: 20px; */
      z-index: 1;
}


.shopLogo img {
  width: 4rem;
  padding: 0.5rem;
}




.Advertise img {
  width: 100%;
  height: 100px;
  object-fit: fill;
}

.exploreLine {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.exploreWord {
  display: flex;
  align-items: center;
  justify-content: center;
}




.menuName h3,


.resInfo {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin: 1rem 0;
}

.resInfoHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.resInfoBtn {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 1rem 0rem;
}

.resInfoDetails {
  background-color: white;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 5px;
}

.resInfoDetails h2 {
  margin-bottom: 0.5rem;
}

.resInfoDetails p {
  margin: 0.2rem 0;
}

.resInfoDetails p span {
  font-weight: bold;
}

.payment {
  min-height: 86.5vh;
}

@media screen and (max-width: 768px) {
  .resInfoDetails {
    padding: 0.5rem;
  }
}

.exploreLine {
  display: flex;
  align-items: center;
}


.exploreWord {
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  padding: 0rem 0.1rem;
}

.hr1 {
  border: 1px solid rgb(138, 140, 146);
  background-color: #f0f0f0;
}

.exploreLine {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  color: rgb(138, 140, 146);
  font-size: 0.8rem;
  margin: 3px 0px;
}

.exploreLine .hr1 {
  border: 1px solid rgb(138, 140, 146);
  display: flex;
  align-items: center;
  justify-content: center;
}

.exploreWord {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  padding: 0rem 0.2rem;
}

.cartHead {
  border: 1px solid rgb(138, 140, 146);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  border-radius: 10px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
  margin: 0.5rem 0rem;
}

.cartNameAndPrice {
  width: 70%;
  padding-left: 1rem;
}

.cartQuant {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.minCart,
.quanCart,
.maxCart {
  padding: 0px 0.2rem;
}

@media screen and (min-width: 800px) {
  .cartQuant {
  }
}

.cartQunatInside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;
  background-color: #ff5722;
  color: white;
  border-radius: 30px;
  margin: 0rem 0.5rem;
  border: 1px solid red;
}

.cartQuant button {
  color: white;
  background-color: #ff5722;
  font-size: 1.5rem;
  border-radius: 30px;
  border: none;
}

.cartCross {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
}

.cartCross button {
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 0.1rem;
}

@media only screen and (min-width: 800px) {
  .Advertise img {
    object-fit: contain;
  }
}

.tableModel {
  padding: 0.3rem;
  font-weight: bold;
  font-size: 1.3rem;
}

.frontePageLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  border: 10px solid #ff5722;
}

.cartIsEmpty {
  color: red;
}



.cart {
  height: 100vh;
}

.cartHeadArrowWord {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0px;
}

.cartLeftArrow {
  width: 10%;
  color: #ff5722;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartLeftArrow h1 {

}
.arrowBtn{
  font-size: 3rem;
    font-weight: bolder;
      color: #ff5722;
      border: none;
      background-color: whitesmoke;
}

.cartWord {
  width: 90%;
  text-align: center;
}

.orderNow {
  margin-top: 1rem;
}

.orderNowInside {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.orderNow h4 {
  font-weight: bold;
  font-size: 1.5rem;
}

.orderNow button {
  color: white;
  background-color: #ff5722;
  border: none;
  padding: 0.5rem;
  border-radius: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}

.suggTextArea {
  background-color: whitesmoke;
  font-size: 1.5rem;
}
.search-list-container input {
  border: none;
}

.inputSearchContainer {
  border: 2px solid rgb(138, 140, 146);
  display: flex;
  border-radius:5px;
  width: 100%;
  /* padding: 0.1 0rem; */
  margin: 0.1rem 0rem;
}

.inputSearchContainer input {
  border: none;
  outline: none;
  width: 100%;
  padding: 0.2rem;
}

.inputContainerSearch button {
  border: none !important;
  font-weight: bold;
  background-color: white;
}

.searchItemResultJi {
  background-color: blue;
  display: flex;
  flex-direction: column;
  position: relative;
  border: none;
  background-color: white;
  position: absolute;
  width: 100%;
}

.searchItemResultJi button {
  background-color: white;
  border: none;
  text-align: left;
  padding: 0.1rem 0px;
  font-weight: bold;
  position: relative;
}






.subItemJiHead{
  width: 100%;
}
.subItemTitle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
























.subItemContent{
  width: 65vw;
  display: flex;
    flex-direction: column;
}
.subItemContent .itemName{
word-wrap: break-word;
overflow-wrap: break-word;
}
.subItemAddButton{
  width: 25vw;
}

.itemDes{
  border:none;
  color:#6a6666;
  font-size: 0.9rem;
}




.scrollerCenter h2{
 
    background-color: whitesmoke;
    border-radius: 10px;
    color: #ff5722;
    font-weight: bold;
 
 
}

.custom-spinner {
  color:#ff5722;

}

.modalClass{
    color: #ff5722;
    font-weight: bold;
    
      padding: 2rem;
      font-size: 2rem;
}

/* */
.scrollerCenter {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ff5722;
  z-index: 100;
}


.scrollerCenter .custom-spinner {
  position: relative;
}

.scrollerCenter h2{
  padding: 1rem;
  margin:1rem
}

.spinnerHead {
  background-color: whitesmoke;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
 
  padding: 1rem;
  margin:1rem;
}



 
.spinnerHead .custom-spinner{
  border:1px solid red #ff5722;
}
.spinnerHead h2 {
border:none}



 


/* CSS */


.crossButton{
  border: none;
  background-color: white;
  font-weight: bold;
  font-size: 1.5rem;
  color: rgb(138, 140, 146);
}




.cateItem{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.cateItem img{
  height: 100px;
  width: 100px;
  border-radius: 50px;
}

.cateShowName h5 {
  text-align: center;
  font-weight: bold;
  color: #ff5722;
  font-size: 1.2rem;
}







.searchItemHead {
  position: relative;
  /* border: 1px solid red; */
}

.searchedItem {
    border: 1px solid red;
  position: absolute;
  background-color: white;
  z-index: 999;
  padding: 0rem 1rem;
  max-height: 30vh;
  overflow: auto;
}
.searchedItem .searchCate{
font-weight: 900;
width: 100%;
border: none;
text-align: left;
padding:0.5rem

}

.searchedItem .searchItemCate {
text-align: left;
border:none;
width: 100%;
margin: 0.1rem 0rem;
font-weight: bold;
font-size: 1rem;
border-radius: 10px;
padding:0.3rem;
}


.noItemFound{
  color : red;

}



.groupImage {
  justify-content: space-between;
  /* Distribute images evenly within the container */
}

.categoryshowImage {
  width: 30%;
  height: 100px;
  /* Adjust the width as per your design */
  object-fit: cover;
  /* Maintain aspect ratio and cover the container */
  border: 1px solid #ddd;
  /* Add a border for visual separation */
  border-radius: 8px;
  /* Optional: Add border-radius for rounded corners */
      height: 100px;
      width: 100px;
      border-radius: 50px;
}


.oneImageSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner-border{
  color:#ff5722
}




.modalButtonHead .modalButton {
  margin:0rem 0.3rem;
}


.headModalSucess h2{
  color:green;
  font-weight: bold;
}

/* .modalHead {
  width: 50%;
}

.firstModel * {
  max-width: 100%;
} */


/* .inputSearchContainer input,
button,
.searchedItem {
  background-color: hsl(25, 100%, 95%);
} */
.inputSearchContainer ,.searchedItem{
background-color: hsl(25, 100%, 95%);
}


.searchedItem {
  border:2px solid black
}



.endUserTermsCond{
  color:blue;
  background-color: white;
  border:none;
  text-decoration: underline;
}

.checkoutHead{
  display: flex;
  flex-direction: row;
}


.cateSection{
 
}


.des-view .bothHeadSub{
  /* border:1px solid red; */
}




.billModal p{
  margin:0px;
  padding:0px;
}
.billModal .cafeName{
  text-align: center;
  color:black;
  font-weight: bold;
  margin:0px;
}

.billModal .add{
text-align: center;
}

.billModal .nameSec{
  display: flex;
  justify-content: space-between;

}

.tabSec {
  display: flex;
  justify-content: flex-end;

}

.purchaseItem{
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0rem;
}
.purchaseItem .item{
  display: flex;
  flex-direction: row;
  padding:0px;  
  border:1px solid rgb(158, 154, 154);
  width: 100%;
  margin:0.1rem 0rem;
}

.itemQuantity{
  padding:0px 0.5rem!important;
  width: 10%;
}

.itemName{
  width: 70%;
}
.itemCost {
  float: right;
  width: 20%;
  text-align: right;
  padding-right: 10px;
}

.subTotal{

  margin-top: 1rem;
}


.tAmt{
display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tAmt p{
  font-size: 1.2rem;
  font-weight: bold;
}



.billModal table {
  width: 100%;
  border-collapse: collapse;
}


.billModal .lAlign {
  width: 50%;
  text-align: left;
}

.billModal .rAlign {
  width: 50%;
  text-align: right;
}

.table1{
  margin:0.5rem 0rem;
}


.billModal {
  width: 100%;
  /* max-height: 90vh; */
}

.billBody{
  padding: 0px;
  max-height: 90vh;
  overflow-y: auto;
}


.receipt{

  
}
.receipt_inside{
padding: 0.5rem!important;
}

.downloadQrHead{
  display: flex;
  justify-content: center;
}
.downloadQr{
  padding:0.3rem;
  margin:0.5rem;
  font-weight: bold;
  border-radius: 5px;
  border:none;
  background-color: #ff5722;
  color: white;
  letter-spacing: 1px;
}

.closeBillHead{
  border:1px solid red;
  display: flex;
  justify-content: flex-end;
}
.closeBill{
  font-weight: bold;
  margin:0.5rem;
  padding:0.2rem;
  background-color: white;
}
.closeBill button{

}



.user{
  position: relative;
}

.preventSection{
  position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
    padding: 1rem;
    border-radius: 10px;
}


.waitSection{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: whitesmoke;

 
}

.preventItem{
font-weight: bold;
font-size: 1.5rem;
color:#ff5722
}

.processSection{
  border:1px solid red;
  background-color: white;
  padding:1rem;
}